/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

//Component
import { MainItem } from './DropdownItem';
import ItemContainer from './ItemContainer';
import MobileMenu from './MobileMenu';

// utils
import { lereroFetch } from 'utils/fetch';
import { synchronizedSingleCartData } from 'utils/manipulate_array';
import { generateCoverImageUrl } from 'utils/generate_cover_image_url';

import { truncateText } from 'utils/helper';
import { useWhitelabelContext } from 'context/whitelabel.context';
import CourseCategories from './CourseCategories';
import Provider from './Provider';
import SearchBar from './SearchBar';
import { isDefaultUrl } from 'utils/is_default_url';

export default function Navbar() {
  const router = useRouter();
  const { header } = useWhitelabelContext();

  const [categories, setCategories] = useState([]);
  const [provider, setProvider] = useState([]);
  const [toggle, setToggle] = useState('');

  const [cartTotal, setCartTotal] = useState(0);
  const [cart, setCart] = useState([]);
  const [showDetailCart, setShowDetailCart] = useState(false);
  const [isSearchBarOpened, setIsSearchBarOpened] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [openedChild, setOpenedChild] = useState('');
  const [openedChildDesktop, setOpenedChildDesktop] = useState('');

  // When user is in cart page, do not show the detail of the cart. This will be applied only in mobile view.
  useEffect(() => {
    if (router.pathname === '/cart') {
      setShowDetailCart(false);
    }
  }, [router]);

  const handleLoadCourseCategories = async () => {
    const response = await lereroFetch('GET', `/categories/dropdown`);
    if (response.success) {
      setCategories(response.data);
    }
  };

  const handleLoadMenuProvider = async () => {
    const response = await lereroFetch('GET', `/items/list/providers`);
    if (response.success) {
      setProvider(response.data);
    }
  };

  useEffect(() => {
    handleLoadCourseCategories();
    handleLoadMenuProvider();
  }, []);

  useEffect(() => {
    let _cart = [];
    if (localStorage.getItem('cart')) {
      _cart = JSON.parse(localStorage.getItem('cart'));
    }

    const synchronizedCartData = _cart.map((item) => {
      return synchronizedSingleCartData({ item: item, isProduct: item.isProduct || false });
    });

    setCart(synchronizedCartData);
    setCartTotal(synchronizedCartData.length);
  }, [router.pathname]);

  return (
    <div className={`nav-wrapper ${isSearchBarOpened ? 'search-bar-opened' : ''}`}>
      <div className="menu-logo d-flex d-lg-none">
        <img className="menu" src="/navbar/menu.svg" alt="menu" onClick={() => setIsMenuOpened(true)} />
      </div>
      <div className="nav-menu nav-menu-left justify-content-lg-start justify-content-center w-lg-25">
        {/* Logo Section */}
        <div className="nav-logo">
          <Link href={'https://lerero.com'} passHref={true}>
            <a target="_blank">
              <img
                className="logo d-lg-flex"
                src="/navbar/lerero_logo_new_2.svg"
                alt="logo"
                style={{ width: '205px', height: '69px' }}
              />
            </a>
          </Link>
        </div>

        <div className="d-lg-flex d-none">
          <ItemContainer setToggle={setToggle} identifier={'category'}>
            {header.enabledCourseCategory && (
              <MainItem toggle={toggle} name={'Select Category'} identifier={'category'}></MainItem>
            )}
            <CourseCategories
              show={toggle === 'category'}
              menu={categories}
              onReset={() => {
                setToggle('');
                setOpenedChildDesktop('');
              }}
              openedChild={openedChildDesktop}
              onOpenChild={setOpenedChildDesktop}
            />
          </ItemContainer>
        </div>

        <div className={!isDefaultUrl() ? 'd-none' : 'd-lg-flex d-none'}>
          <ItemContainer setToggle={setToggle} identifier={'providers'}>
            <MainItem name={'Providers'} toggle={toggle} identifier={'providers'}></MainItem>
            <Provider
              show={toggle === 'providers'}
              menu={provider}
              onReset={() => {
                setToggle('');
                setOpenedChildDesktop('');
              }}
            />
          </ItemContainer>
        </div>
      </div>

      <div className="nav-menu nav-menu-right w-100">
        <SearchBar onChangeCondition={(condition) => setIsSearchBarOpened(condition)} />

        {/* Cart Icon */}
        {/* onTouchEnd is fired when click the cart. When click the cart, do not show the detail of the cart so set it to false */}
        <div
          className="nav-menu__cart cursor-pointer"
          onMouseEnter={() => setShowDetailCart(true)}
          onMouseLeave={() => setShowDetailCart(false)}
          onTouchEnd={() => setShowDetailCart(false)}
          onClick={() => Router.push('/cart')}
        >
          <font color="#0666f0" className="cart-logo">
            <i className="bi bi-cart2 d-lg-inline d-flex"></i>
          </font>
          {cartTotal !== 0 ? (
            <button className="cart-bubble" onClick={() => Router.push('/cart')}>
              {cartTotal}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>

      {/* Cart List */}
      <div className={`div-cart ${showDetailCart ? '' : 'display-none'}`}>
        <table width="100%">
          <tbody>
            {cart.length > 0 ? (
              cart.map((data, index) => {
                return (
                  <tr key={index}>
                    <td width="22%" style={{ verticalAlign: 'top' }}>
                      <center>
                        <img
                          style={{ borderRadius: '50px' }}
                          className="landing-card-image profile-small"
                          src={generateCoverImageUrl(data.image)}
                          alt="Bedroom organization"
                        />
                      </center>
                    </td>
                    <td className="p-1">
                      <p className="font-size-13 mb-2 font-weight-700">{truncateText(data.name, 27)}</p>
                      <p className="font-size-12 text-secondary">{truncateText(data.categoryName, 27)}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td width="22%" style={{ verticalAlign: 'top' }}></td>
                <td className="p-1">
                  <p className="font-size-13 mb-2 font-weight-700">{''}</p>
                  <p className="font-size-12 text-secondary">{'There is no item'}</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Menu in Mobile */}
      <MobileMenu
        menu={categories}
        provider={provider}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        openedChild={openedChild}
        setOpenedChild={setOpenedChild}
        toggle={toggle}
        setToggle={setToggle}
      />
    </div>
  );
}

export const BUTTON_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  addedToCart: 'addedToCart',
  addedToCartDarker: 'addedToCartDarker',
};

export const BUTTON_ROUNDNESS = {
  None: '0px',
  S: '4px',
  M: '8px',
  L: '12px',
  Full: '40px',
};

export const BUTTON_CLASSNAMES = {
  [BUTTON_TYPE.primary]: 'button-contained',
  [BUTTON_TYPE.secondary]: 'button-outline',
  [BUTTON_TYPE.disabled]: 'button-disable',
  [BUTTON_TYPE.addedToCart]: 'button-contained button-contained-pink',
  [BUTTON_TYPE.addedToCartDarker]: 'button-contained button-contained-darker',
};

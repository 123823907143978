import React from 'react';

const OpenedChildMenu = ({ slug, onClick, name }) => {
  return (
    <div className="mobile-menu__child" onClick={() => onClick()}>
      <div>{name}</div>
    </div>
  );
};

export default OpenedChildMenu;

import { BUTTON_ROUNDNESS } from 'constants/button';
import { defaultWhitelabelSettings } from 'constants/defaultWhitelabel';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { lereroFetch } from 'utils/fetch';
import {
  FONT_GROUPS,
  FONT_GROUPS_1,
  FONT_GROUPS_2,
  FONT_GROUPS_3,
  FONT_GROUPS_4,
  FONT_GROUPS_5,
} from 'variables/font_groups';

const WhitelabelContext = createContext(undefined);

export const useWhitelabelContext = () => {
  return useContext(WhitelabelContext);
};

export const WhitelabelProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [whitelabelSettings, setWhitelabelSettings] = useState(null);

  const generateRadiusInPx = useCallback((radius) => {
    return BUTTON_ROUNDNESS[radius] ?? BUTTON_ROUNDNESS.S;
  }, []);

  const generateFontGroups = useCallback((selectedHeading) => {
    return FONT_GROUPS[selectedHeading - 1] ?? FONT_GROUPS[0];
  }, []);

  const lmsDomain = (domain) => {
    return domain.replace(
      process.env.NEXT_PUBLIC_MARKETPLACE_WHITELABEL_SUFFIX,
      process.env.NEXT_PUBLIC_LMS_WHITELABEL_SUFFIX
    );
  };

  const handleGetWhitelabelSettings = async (domain) => {
    setLoading(true);
    const response = await lereroFetch('GET', `/settings/company?domain=${lmsDomain(domain)}`, {}, false, true);

    if (response.success) {
      setWhitelabelSettings(response.data);
    } else {
      setWhitelabelSettings(defaultWhitelabelSettings);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (window) {
      handleGetWhitelabelSettings(window.location.hostname.replace(/^www\.(?=[^.]*\.)/i, ''));
    }
  }, []);

  return loading ? (
    <div className="loading">
      <Spinner animation="border" variant="white" />
    </div>
  ) : (
    <WhitelabelContext.Provider
      value={{
        header: whitelabelSettings.marketplaceHeader,
        footer: whitelabelSettings.marketplaceFooter,
        lookAndFeel: {
          ...whitelabelSettings.appearanceLookAndFeel,
          fontGroups: generateFontGroups(whitelabelSettings.appearanceLookAndFeel.typography.size),
          button: {
            ...whitelabelSettings.appearanceLookAndFeel.button,
            roundness: generateRadiusInPx(whitelabelSettings.appearanceLookAndFeel.button.roundness),
          },
        },
      }}
    >
      <FontWrapper font={whitelabelSettings.appearanceLookAndFeel.typography.style}>{children}</FontWrapper>
    </WhitelabelContext.Provider>
  );
};

const FontWrapper = styled.div`
  font-family: ${({ font }) => (font ? `${font}, sans-serif !important` : `'Titillium Web', sans-serif !important;`)};
`;

import React from 'react';
import { NoChildItem } from './DropdownItem';
import { useRouter } from 'next/router';

const Provider = ({ menu, onReset, show }) => {
  const router = useRouter();

  return (
    <div className={show ? 'd-flex position-absolute pt-3' : 'd-none'}>
      <div className="nav-child" style={{ maxHeight: '350px', top: '120%', overflowY: 'scroll' }}>
        {menu.map((menuData, index) => {
          return (
            <NoChildItem
              key={index}
              onClickFunction={() => {
                onReset();
                router.push({
                  pathname: '/category',
                  query: { provider: menuData.id, name: menuData.name },
                });
              }}
              name={menuData.name}
            ></NoChildItem>
          );
        })}
      </div>
    </div>
  );
};

export default Provider;

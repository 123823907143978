export const helpMenuItems = [
  {
    menuName: 'Privay Policy',
    link: 'https://lerero.com/privacy-policy/',
  },
  {
    menuName: 'Terms and Conditions',
    link: 'https://lerero.com/terms-and-conditions/',
  },
  {
    menuName: 'FAQs',
    link: 'https://lerero.com/faqs/',
  },
];

export const platformMenuItems = [
  {
    menuName: 'Features',
    link: 'https://lerero.com/features/',
  },
  {
    menuName: 'Request Demo',
    link: 'https://lerero.com/request-demo/',
  },
];

export const companyMenuItems = [
  {
    menuName: 'About Us',
    link: 'https://lerero.com/about-us/',
  },
  {
    menuName: 'Contact Us',
    link: 'https://lerero.com/contact-us/',
  },
];

export const socialMenuItems = {
  twitter: {
    id: 'twitter',
    icon: '/footer/fa-twitter.svg',
    alt: 'Twitter',
  },
  facebook: {
    id: 'facebook',
    icon: '/footer/fa-facebook-square.svg',
    alt: 'Facebook',
  },
  linkedin: {
    id: 'linkedin',
    icon: '/footer/fa-linkedin.svg',
    alt: 'LinkedIn',
  },
  instagram: {
    id: 'instagram',
    icon: '/footer/fa-instagram.svg',
    alt: 'Instagram',
  },
  youtube: {
    id: 'youtube',
    icon: '/footer/fa-youtube.svg',
    alt: 'Youtube',
  },
  medium: {
    id: 'medium',
    icon: '/footer/fa-medium.svg',
    alt: 'Medium',
  },
  tiktok: {
    id: 'tiktok',
    icon: '/footer/fa-tiktok.svg',
    alt: 'Tiktok',
  },
};

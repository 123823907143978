import React from 'react';
import Router from 'next/router';

// Utils
import { cancelEventBubble } from 'utils/cancel_event_bubble';
import ChildMenu from './Mobile/ChildMenu';
import PrimaryMenu from './Mobile/PrimaryMenu';
import { useWhitelabelContext } from 'context/whitelabel.context';

const MobileMenu = (props) => {
  const { isMenuOpened, toggle, menu, provider, openedChild, setOpenedChild, setIsMenuOpened, setToggle } = props;
  const { header } = useWhitelabelContext();

  const handleClose = () => {
    setIsMenuOpened(false);
    handleCloseToggle();
  };

  const handleCloseToggle = () => {
    setToggle('');
    setOpenedChild('');
  };

  return (
    <div className={`mobile-menu d-lg-none ${isMenuOpened ? 'opened' : ''}`} onClick={() => setIsMenuOpened(false)}>
      <div className="mobile-menu-container" onClick={(e) => cancelEventBubble(e)}>
        <div className="mobile-menu-content">
          {header.enabledCourseCategory && (
            <PrimaryMenu
              title="Select Category"
              onClose={handleCloseToggle}
              onClick={(val) => setToggle(val)}
              toggle={toggle}
              toggleValue={'category'}
            />
          )}

          {toggle === 'category' &&
            menu.map((item) => {
              return (
                <ChildMenu
                  key={item.categorySlug}
                  slug={item.categorySlug}
                  name={item.categoryName}
                  isHasChild
                  childrenArray={item.children}
                  setOpenedChild={setOpenedChild}
                  openedChild={openedChild}
                  handleClose={handleClose}
                />
              );
            })}
        </div>

        <div className="mobile-menu-content">
          <PrimaryMenu
            title="Providers"
            onClose={handleCloseToggle}
            onClick={(val) => setToggle(val)}
            toggle={toggle}
            toggleValue="providers"
          />
          {toggle === 'providers' &&
            provider.map((item) => {
              return item.name.replace(/\s/g, '') !== '' ? (
                <ChildMenu key={item.id} isHasChild={false} name={item.name} id={item.id} handleClose={handleClose} />
              ) : null;
            })}
        </div>
      </div>

      {/* Close Button */}
      <div className="mobile-menu-close">
        <font color="#323232" className="p-0 p-lg-3 close-logo d-lg-none" onClick={() => handleClose()}>
          <i className="bi bi-x d-lg-inline d-flex"></i>
        </font>
      </div>
    </div>
  );
};

export default MobileMenu;

export const FONT_TYPES = {
  heading: 'heading',
  subHeading: 'subHeading',
  textBody: 'textBody',
};

export const FONT_GROUPS_1 = {
  [FONT_TYPES.heading]: [48, 40, 32, 24, 20],
  [FONT_TYPES.subHeading]: [40, 32, 24, 20, 18],
  [FONT_TYPES.textBody]: [32, 24, 20, 18, 16],
};

export const FONT_GROUPS_2 = {
  [FONT_TYPES.heading]: [40, 32, 24, 20, 18],
  [FONT_TYPES.subHeading]: [32, 24, 20, 18, 16],
  [FONT_TYPES.textBody]: [24, 20, 18, 16, 14],
};

export const FONT_GROUPS_3 = {
  [FONT_TYPES.heading]: [32, 24, 20, 18, 16],
  [FONT_TYPES.subHeading]: [24, 20, 18, 16, 14],
  [FONT_TYPES.textBody]: [20, 18, 16, 14, 12],
};

export const FONT_GROUPS_4 = {
  [FONT_TYPES.heading]: [24, 20, 18, 16, 14],
  [FONT_TYPES.subHeading]: [20, 18, 16, 14, 12],
  [FONT_TYPES.textBody]: [18, 16, 14, 12, 10],
};

export const FONT_GROUPS_5 = {
  [FONT_TYPES.heading]: [20, 18, 16, 14, 12],
  [FONT_TYPES.subHeading]: [18, 16, 14, 12, 10],
  [FONT_TYPES.textBody]: [16, 14, 12, 10, 8],
};

export const FONT_GROUPS = [FONT_GROUPS_1, FONT_GROUPS_2, FONT_GROUPS_3, FONT_GROUPS_4, FONT_GROUPS_5];

export const FONT_WEIGHT = {
  [FONT_TYPES.heading]: '700',
  [FONT_TYPES.subHeading]: '600',
  [FONT_TYPES.textBody]: '400',
};

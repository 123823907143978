import { TextBody } from 'components/Typography/General';
import Link from 'next/link';
import React from 'react';
import { isMobile } from 'react-device-detect';

const FooterMenuItem = (props) => {
  const { link, menuName } = props;
  return (
    <TextBody variety={isMobile ? 3 : 5}>
      <Link href={link} passHref>
        <a target="_blank">
          <font className="font-titilium text-white" style={{ marginRight: '20px' }}>
            {menuName}
          </font>
        </a>
      </Link>
    </TextBody>
  );
};

export default FooterMenuItem;

import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import OpenedChildMenu from './OpenedChildMenu';

const ChildMenu = (props) => {
  const router = useRouter();
  const { isHasChild, name, id, slug, openedChild, setOpenedChild, handleClose, childrenArray } = props;

  const handleRedirect = useCallback(
    (query) => {
      handleClose();
      router.push({ pathname: '/category', query });
    },
    [handleClose, router]
  );

  const handleOnClickChild = () => {
    childrenArray?.length > 0
      ? setOpenedChild(openedChild !== slug ? slug : '')
      : handleRedirect({ category: slug, name: name });
  };

  return isHasChild ? (
    <div key={slug} className={`mobile-menu__menu-container ${openedChild === slug ? 'menu-opened' : ''}`}>
      <div className="mobile-menu__parent" onClick={handleOnClickChild}>
        <div>{name}</div>
        {childrenArray?.length > 0 && <i className="bi bi-chevron-down" />}
      </div>
      {childrenArray?.length > 0 && openedChild.includes(slug) && (
        <>
          {childrenArray.map((child) => {
            return (
              <OpenedChildMenu
                key={child.categorySlug}
                onClick={() => handleRedirect({ category: child.categorySlug, parent: slug })}
                name={child.categoryName}
              />
            );
          })}
          <div className="button-view-all" onClick={() => handleRedirect({ category: slug, name: name })}>
            View All
          </div>
        </>
      )}
    </div>
  ) : (
    <div key={id} className="mobile-menu__menu-container" onClick={() => handleRedirect({ provider: id, name: name })}>
      <div className="mobile-menu__parent">
        <span>{name}</span>
      </div>
    </div>
  );
};

export default ChildMenu;

import { BUTTON_TYPE } from 'constants/button';

export const getButtonStyles = (button) => {
  return {
    [BUTTON_TYPE.primary]: {
      radius: button.roundness,
      bg: button.bg_color,
      textColor: button.text_color,
      border: button.border_color,
    },
    [BUTTON_TYPE.addedToCartDarker]: {
      radius: button.roundness,
      bg: button.bg_color,
      textColor: button.text_color,
      border: button.border_color,
    },
    [BUTTON_TYPE.secondary]: {
      bg: '#ffffff',
      textColor: button.bg_color,
      border: button.bg_color,
      radius: button.roundness,
    },
    [BUTTON_TYPE.addedToCart]: {
      bg: '',
      textColor: button.text_color,
      border: '',
      radius: button.roundness,
    },
    [BUTTON_TYPE.disabled]: {
      bg: '',
      textColor: '',
      border: '',
      radius: button.roundness,
    },
  };
};

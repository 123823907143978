import React from 'react';
import { NoChildItem } from './DropdownItem';
import { useRouter } from 'next/router';
import { TextBody } from 'components/Typography/General';

const CourseCategories = ({ menu, show, onOpenChild, openedChild, onReset }) => {
  const router = useRouter();

  const handleOnChangeRoute = (query) => {
    onReset();
    router.push({
      pathname: '/category',
      query,
    });
  };

  return (
    <div className={show ? 'd-flex position-absolute pt-3' : 'd-none'}>
      <div className="nav-child">
        <NoChildItem
          onClickFunction={() => handleOnChangeRoute({ category: 'all' })}
          name={'All Categories'}
        ></NoChildItem>

        {menu.map((category, index) => {
          return category.children && category.children.length > 0 ? (
            <div
              className="navbar-with-child___container"
              onMouseEnter={() => onOpenChild(`${category.categorySlug}-${category.categoryName}`)}
              onMouseLeave={() => onOpenChild(``)}
              onTouchEnd={() => onOpenChild(``)}
            >
              <div className="navbar-with-child___item">
                <TextBody
                  variety={5}
                  key={`child-${index + 1}`}
                  onClick={() => onOpenChild(`${category.categorySlug}-${category.categoryName}`)}
                >
                  {category.categoryName}
                </TextBody>
                <span className="chevron-image"></span>
              </div>
              <div
                className={`navbar-category-child ${
                  openedChild === `${category.categorySlug}-${category.categoryName}` ? 'd-block' : ''
                }`}
              >
                {category.children.map((child, childIndex) => {
                  return (
                    <NoChildItem
                      key={`child-${childIndex + 1}`}
                      onClickFunction={() =>
                        handleOnChangeRoute({
                          category: child.categorySlug,
                          parent: category.categorySlug,
                        })
                      }
                      name={child.categoryName}
                    ></NoChildItem>
                  );
                })}
                <div className="navbar-all-item___container">
                  <TextBody
                    variety={5}
                    className="navbar-all-item___item"
                    key={`child-${category.children.length + 1}`}
                    onClick={() =>
                      handleOnChangeRoute({
                        category: category.categorySlug,
                        name: category.categoryName,
                      })
                    }
                  >
                    {`View All ${category.categoryName} Courses`}
                  </TextBody>
                </div>
              </div>
            </div>
          ) : (
            <NoChildItem
              onClickFunction={() => handleOnChangeRoute({ category: category.categorySlug })}
              name={category.categoryName}
            ></NoChildItem>
          );
        })}
      </div>
    </div>
  );
};

export default CourseCategories;

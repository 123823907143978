import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import FooterMenuItem from './FooterMenuItem';
import FooterMenuTitle from './FooterMenuTitle';
import SocialMediaItem from './SocialMediaItem';
import { socialMenuItems } from 'constants/footer';
import { useWhitelabelContext } from 'context/whitelabel.context';
import { TextBody } from 'components/Typography/General';

import { isMobile } from 'react-device-detect';

const pageWithBottonSheet = ['/cart', '/checkout', '/courses/[courseId]', '/products/[productId]'];
const bottomSheetClassName = {
  ['/cart']: 'cart',
  ['/checkout']: 'checkout',
  ['/courses/[courseId]']: 'course-id',
  ['/products/[productId]']: 'product-id',
};

const Footer = () => {
  const router = useRouter();
  const { footer } = useWhitelabelContext();

  const isHaveBottomSheet = useCallback((pathname) => {
    return pageWithBottonSheet.includes(pathname);
  }, []);

  const footerSocialMedia = useMemo(() => {
    return footer.socialMedia.filter((item) => item.link).map((item) => ({ ...item, ...socialMenuItems[item.name] }));
  }, [footer.socialMedia]);

  return (
    <CustomizedFooter
      bg={footer.color}
      className={`footer-wrapper ${
        isHaveBottomSheet(router.pathname) ? bottomSheetClassName[router.pathname] : 'no-bottomsheet'
      } fixed-bottom`}
    >
      <div className="display-flex flex-column flex-lg-row align-items-start mb-3">
        {footer?.menu.map((item, index) => {
          return (
            <div key={index} className={`mb-3 mb-lg-0 ${index !== 0 ? 'ms-0 ms-lg-5' : ''}`}>
              <FooterMenuTitle title={item.menuName}></FooterMenuTitle>
              {item.menu.map((menuItem) => {
                return (
                  <FooterMenuItem link={menuItem.link} menuName={menuItem.name} key={menuItem.name}></FooterMenuItem>
                );
              })}
            </div>
          );
        })}
      </div>

      {/* Social Media Section */}
      <div className="footer-container d-flex flex-column-reverse flex-lg-row align-items-center align-items-lg-end mt-5 mt-lg-0">
        <div className="footer-menu-container col-lg-4 col-12 justify-content-center justify-content-lg-start pt-4">
          <TextBody tag="span" variety={isMobile ? 4 : 5} className="font-titilium text-white font-size-14">
            {footer.copyrights}
          </TextBody>
        </div>
        <center className="col-lg-4 col-12">
          {footerSocialMedia.map((menuItem) => {
            return (
              <SocialMediaItem
                key={menuItem.alt}
                link={menuItem.link}
                alt={menuItem.alt}
                icon={menuItem.icon}
              ></SocialMediaItem>
            );
          })}
        </center>
      </div>
      <div className="footer-top-pattern"></div>
    </CustomizedFooter>
  );
};

export default Footer;

const CustomizedFooter = styled.footer`
  background: ${({ bg }) => (bg ? `${bg} !important` : '')};
`;

import { useWhitelabelContext } from 'context/whitelabel.context';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { FONT_TYPES, FONT_WEIGHT } from 'variables/font_groups';

export const Heading = ({ variety = 1, tag = 'h1', children, ...props }) => (
  <Typography type={FONT_TYPES.heading} variety={variety} tag={tag} {...props}>
    {children}
  </Typography>
);

export const SubHeading = ({ variety = 1, tag = 'h4', children, ...props }) => (
  <Typography type={FONT_TYPES.subHeading} variety={variety} tag={tag} {...props}>
    {children}
  </Typography>
);

export const TextBody = ({ variety = 1, tag = 'p', children, ...props }) => (
  <Typography type={FONT_TYPES.textBody} variety={variety} tag={tag} {...props}>
    {children}
  </Typography>
);

const Typography = ({ tag, type = FONT_TYPES.heading, variety = 1, children, ...props }) => {
  const { lookAndFeel } = useWhitelabelContext();

  const size = lookAndFeel.fontGroups[type][variety - 1];
  const weight = FONT_WEIGHT[type] || '500';

  const styles = useMemo(
    () => css`
      font-size: ${size / 10}rem !important;
      font-weight: ${weight} !important;
    `,
    [size, weight]
  );

  const StyledComponent = styled(tag)`
    ${styles}
  `;

  return <StyledComponent {...props}>{children}</StyledComponent>;
};

export default Typography;

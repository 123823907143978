import Button from 'components/Button';
import { useWhitelabelContext } from 'context/whitelabel.context';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FONT_TYPES } from 'variables/font_groups';

const SearchBar = ({ onChangeCondition }) => {
  const searchRef = useRef(null);
  const router = useRouter();
  const [keyword, setKeyword] = useState('');
  const { lookAndFeel } = useWhitelabelContext();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.get('search');
    if (search) setKeyword(search);

    if (!router.asPath.includes('/category?search=')) {
      setKeyword('');
    }
  }, [router.asPath]);

  return (
    <SearchBarContainer
      radius={lookAndFeel.button.roundness}
      border={lookAndFeel.button.border_color}
      className="nav-menu__search-bar"
    >
      <font
        color={lookAndFeel.button.bg_color}
        className="p-0 p-lg-3 search-logo"
        onClick={() => onChangeCondition(true)}
      >
        <i className="bi bi-search d-lg-inline d-flex"></i>
      </font>
      <form className="d-flex w-100" onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          className="search-field width-100 font-size-14 d-lg-flex d-none"
          placeholder="Search for courses/products/bundles"
          value={keyword}
          ref={searchRef}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button
          className="search-button display-right mt-0 p-0 d-lg-inline-block d-none fw-bold h-auto"
          onClick={() => {
            router.push(`/category?search=${keyword}`);
            searchRef.current.blur();
          }}
        >
          Search
        </Button>
      </form>
      <font
        color={lookAndFeel.button.bg_color}
        className="p-0 p-lg-3 font-size-24 close-logo d-lg-none"
        onClick={() => {
          onChangeCondition(false);
          setKeyword('');
        }}
      >
        <i className="bi bi-x d-lg-inline d-flex"></i>
      </font>
    </SearchBarContainer>
  );
};

export default SearchBar;

const SearchBarContainer = styled.div`
  border: 1px solid ${({ border }) => border || 'var(--color-primary-blue)'};
  border-radius: ${({ radius }) => `4px ${radius} ${radius} 4px !important`};
`;

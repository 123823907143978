export const defaultWhitelabelSettings = {
  marketplaceHeader: {
    enabledProduct: false,
    enabledCourseCategory: true,
  },
  marketplaceFooter: {
    color: '#066bfb',
    copyrights: '© 2023 Lerero. All rights reserved.',
    menu: [
      {
        menuName: 'Help',
        menu: [
          {
            name: 'Privacy & Policy',
            link: 'https://lerero.com/privacy-policy/',
          },
          {
            name: 'Terms and Conditions',
            link: 'https://lerero.com/terms-and-conditions/',
          },
          {
            name: 'FAQs',
            link: 'https://lerero.com/faqs/',
          },
        ],
      },
      {
        menuName: 'Platform',
        menu: [
          {
            name: 'Features',
            link: 'https://lerero.com/features/',
          },
          {
            name: 'Request Demo',
            link: 'https://lerero.com/request-demo/',
          },
        ],
      },
      {
        menuName: 'Company',
        menu: [
          {
            name: 'About Us',
            link: 'https://lerero.com/about-us/',
          },
          {
            name: 'Contact Us',
            link: 'https://lerero.com/contact-us/',
          },
        ],
      },
    ],
    socialMedia: [
      {
        name: 'twitter',
        link: 'https://twitter.com/lerero_hq',
      },
      {
        name: 'facebook',
        link: 'https://www.facebook.com/profile.php?id=100090685698996&is_tour_completed=true',
      },
      {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/lerero/',
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/lerero_official/',
      },
      {
        name: 'youtube',
        link: 'https://www.youtube.com/channel/UCmD-Tz6xJYwvHa4x3wcrcjQ',
      },
      {
        name: 'medium',
        link: 'https://medium.com/@Lerero',
      },
      {
        name: 'tiktok',
        link: '',
      },
    ],
  },
  appearanceLookAndFeel: {
    favicon: '',
    logo: '',
    language: 'en',
    typography: {
      style: 'Titillium Web',
      color: '#000000',
      size: 2,
    },
    button: {
      roundness: 'S',
      text_color: '#ffffff',
      bg_color: '#066bfb',
      border_color: '#066bfb',
    },
  },
};

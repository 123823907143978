import { BUTTON_CLASSNAMES, BUTTON_TYPE } from 'constants/button';
import { useWhitelabelContext } from 'context/whitelabel.context';
import React, { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { getButtonStyles } from 'utils/whitelabel';
import { FONT_WEIGHT } from 'variables/font_groups';

const Button = forwardRef(
  ({ type = BUTTON_TYPE.primary, fontType, variety, className = '', children, ...props }, ref) => {
    const { lookAndFeel } = useWhitelabelContext();

    const buttonType = useMemo(() => {
      return BUTTON_CLASSNAMES[type] ?? BUTTON_TYPE.primary;
    }, [type]);

    const fontGroupType = useMemo(() => {
      if (fontType) {
        return { size: lookAndFeel.fontGroups[fontType][variety], weight: FONT_WEIGHT[fontType] || '500' };
      }

      return null;
    }, [fontType, variety, lookAndFeel]);

    const buttonStyle = useMemo(() => {
      const styles = getButtonStyles(lookAndFeel.button);
      return styles[type] ?? styles[BUTTON_TYPE.primary];
    }, [type, lookAndFeel.button]);

    return (
      <CustomizableButton
        {...buttonStyle}
        {...fontGroupType}
        className={`button ${buttonType} ${className}`}
        {...props}
      >
        {type === BUTTON_TYPE.addedToCartDarker ? <span>{children}</span> : children}
      </CustomizableButton>
    );
  }
);

export default Button;

Button.displayName = 'Button';

const CustomizableButton = styled.button`
  background: ${({ bg }) => (bg ? `${bg} !important` : '')};
  color: ${({ textColor }) => (textColor ? `${textColor} !important` : '')};
  border-radius: ${({ radius }) => `${radius} !important`};
  border: ${({ border }) => (border ? `1px solid ${border} !important` : '')};

  font-size: ${({ size }) => (size ? `${size / 10}rem !important` : '')};
  font-weight: ${({ weight }) => (weight ? `${weight} !important` : '')};
`;

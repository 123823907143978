import React from 'react';

const PrimaryMenu = (props) => {
  const { toggle, toggleValue, onClose, onClick, title, noToggle } = props;
  const menuOpened = !noToggle && toggle === toggleValue;

  return (
    <div
      className={`mobile-menu__menu-title ${!noToggle && menuOpened ? 'menu-opened' : ''}`}
      onClick={menuOpened ? onClose : () => onClick(toggleValue)}
    >
      <div>{title}</div>
      {!noToggle ? <i className="bi bi-chevron-down" /> : null}
    </div>
  );
};

export default PrimaryMenu;

import { SubHeading } from 'components/Typography/General';
import React from 'react';
import { isMobile } from 'react-device-detect';

const FooterMenuTitle = (props) => {
  const { title } = props;

  return (
    <SubHeading tag="p" variety={isMobile ? 3 : 4}>
      <font className="font-titilium text-white font-weight-700">{title}</font>
    </SubHeading>
  );
};

export default FooterMenuTitle;

import { TextBody } from 'components/Typography/General';
import React from 'react';
import ICONS from 'utils/icons';

export const NoChildItem = ({ onClickFunction, name }) => {
  return (
    <div className="navbar-no-child___container">
      <TextBody variety={5} className="navbar-no-child___item" onClick={() => onClickFunction()}>
        {name}
      </TextBody>
    </div>
  );
};

export const MainItem = ({ name, toggle, identifier, noToggle }) => {
  return (
    <div className="nav-menu__dropdown cursor-pointer">
      <TextBody tag="span" variety={4}>
        {name}
      </TextBody>
      {!noToggle ? (
        <img src={ICONS.chevronDownBlack} className={`${toggle === identifier ? 'opened' : ''}`}></img>
      ) : null}
    </div>
  );
};
